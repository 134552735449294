<template>
  <navbar :transparent="(user.uid?false:true)" :position="(user.uid?'static':'absolute')" type="black" :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <div class="navbar-brand text-white d-flex">
        <a v-if="previousRoute && !loading" @click="back" :class="'navbar-back text-white ml-2 mr-4 d-none d-md-inline-block'+(order || completeProductionContract || completeBidRequest ?' mt-3':(commodity || productionContract || bidRequest)?' mt-4':' mt-0')"><i class="fa fa-chevron-left"></i></a>
        <b-spinner v-if="loading" />
        <span v-else>
          <!-- <small class="d-none d-md-block text-muted" v-if="((user.uid) && (title == 'Dashboard') && (!user.role.admin))">{{ (user.role.buyer) ? 'Buyer' : 'Farmer' }} ID: {{ user.uid }}</small> -->
          <small class="d-none d-md-block text muted" v-if="((user.uid) && (title == 'Dashboard') && (!user.role.admin))">User ID: {{ user.uid.substr(0, 10) }}</small>
          <small class="d-none d-md-block text-muted" v-if="commodity">
            <span class="d-block d-md-inline"> ID: {{user.uid.substr(0,10).toUpperCase()}}</span>
            <span v-if="commodity.producer&&commodity.producer.years" class="d-block d-md-inline">
              <span class="d-none d-md-inline"> | </span>
                  {{commodity.producer.years}} years in production
              </span>
              <span v-if="commodity.producer&&commodity.producer.verified" class="text-info ml-0 ml-md-2 d-block d-md-inline">
                <i class="fa fa-check-circle mr-1"></i>Verified
              </span>
          </small>
          <!-- <small class="d-none d-md-block text-muted" v-if="commodity"><span class="d-block d-md-inline" v-if="commodity.userID">Farmer ID: {{commodity.userID.substr(0,10).toUpperCase()}}</span><span v-if="commodity.producer&&commodity.producer.years" class="d-block d-md-inline"><span class="d-none d-md-inline"> | </span>{{commodity.producer.years}} years in production</span><span v-if="commodity.producer&&commodity.producer.verified" class="text-info ml-0 ml-md-2 d-block d-md-inline"><i class="fa fa-check-circle mr-1"></i>Verified</span></small> -->
          
          <small class="d-block text-muted" v-else-if="productionContract"> <span class="d-block d-md-inline" v-if="productionContract.userID">User ID: {{ user.uid.substr(0,10).toUpperCase() }}</span> </small>
          <small class="d-block text-muted" v-else-if="bidRequest"> <span class="d-block d-md-inline" v-if="bidRequest.userID">User ID: {{ user.uid.substr(0,10).toUpperCase() }}</span> </small>
          
          <small class="d-block text-muted" v-else-if="order"><span class="d-block d-md-inline">ID: {{order.id.toUpperCase()}}</span></small>
          <small class="d-block text-muted" v-else-if="completeProductionContract"><span class="d-block d-md-inline" v-if="completeProductionContract.id">ID: {{ completeProductionContract.id.toUpperCase() }}</span></small>
          <small class="d-block text-muted" v-else-if="completeBidRequest"><span class="d-block d-md-inline" v-if="completeBidRequest.id">ID: {{ completeBidRequest.id.toUpperCase() }}</span></small>
          <span class="d-block">{{title}}</span>
          <small class="d-block text-wrap" v-if="commodity">{{commodity.category}}<span v-if="commodity.variety">, {{commodity.variety}}</span></small>
          <!-- <small class="d-none d-md-block text-wrap" v-if="productionContract">{{ productionContract.category }}<span v-if="productionContract.variety">, {{ productionContract.variety }} </span> </small> -->
        </span>
      </div>
    </div>
  </navbar>
</template>
<script>
import {bus} from '@/vuex/bus.js';
import {mapState} from 'vuex';
import { Navbar, NavbarToggleButton } from "@/components";

export default {
  components: {
    Navbar,
    NavbarToggleButton
  },
  computed: {
    ...mapState(['user']),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      title:null,
      commodity:null,
      order:null,
      previousRoute:null,
      productionContract:null,
      bidRequest: null,
      completeProductionContract: null,
      completeBidRequest: null,
      loading:true
    };
  },
  mounted(){
    let vm = this;
    vm.title=vm.$route.name;
    vm.loading=true;
    bus.$on('backToTop',()=>{
      vm.back();
    });
    bus.$on('prepareTitle',(obj)=>{
      vm.commodity=null;
      vm.productionContract=null;
      vm.bidRequest = null;
      vm.completeProductionContract=null;
      vm.completeBidRequest = null;
      vm.order=null;
      if(!obj) {
        vm.title=vm.$route.name;
      }else {
        if(obj.orderID){
          vm.title='Order Detail';
          vm.order={...obj};
        } else if(obj.isProductionContract) {
            vm.title=obj.subtype + ' ' + obj.type;
            vm.productionContract={...obj};
        } else if(obj.isBidRequest){
            vm.title=obj.subtype + ' ' + obj.type;
            vm.bidRequest={...obj};
        } else if(obj.isCompleteProductionContract){
            vm.title='Completed production contract details';
            vm.completeProductionContract={...obj};
        } else if(obj.isCompleteBidRequest){
            vm.title='Completed product bid request details';
            vm.completeBidRequest= {...obj};
        } else{
          vm.title=obj.subtype + ' ' + obj.type;
          vm.commodity={...obj};
        }
      }
      vm.loading=false;

    });
    if(!vm.$route.meta.dynamicTitle){
      vm.loading=false;
    }
  },
  watch: {
    $route(to, from) {
      let vm = this;
      if(from.meta.root&&to.meta.dynamicTitle){
        vm.loading=true;
        vm.previousRoute=from;
      }else {
        vm.previousRoute=null;
      }
      vm.title=vm.$route.name;
     
    }
  },
  methods: {
    back(){
      let vm = this;
      if(!vm.commodity&&!vm.previousRoute){
        vm.$router.push('/');
      }
      if(vm.commodity&&vm.commodity.purchasing){
        bus.$emit('backToMarketplace');
        return;
      }

      if(vm.productionContract&&vm.productionContract.isProductionContract){
        vm.$router.push("/production/contract");
      }

      if(vm.previousRoute){
        vm.$router.push(vm.previousRoute);
        return;
      }
      bus.$emit('backToMarketplace');
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    }
  }
};
</script>
<style></style>
