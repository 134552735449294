<template>
    <div class="container">
       <div v-if="loading" class="row mt-4">
           <div class="col d-none d-md-flex"></div>
           <div class="col-12 col-md-8 col-xl-6 pt-4">
               <card class="card-lock text-center mt-4 pb-4" no-footer-line>
               <img slot="header" src="/img/logo@2x.png" class="img-fluid" />
               <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
               </card>
           </div>
           <div class="col d-none d-md-flex"></div>
       </div>
       <div v-else class="row">
           <div class="col-12 pr-0 pl-0">
               <b-card class="">
                   <b-card-header>
                       <div class="d-flex flex-row align-items-center p-2 justify-content-between">
                           <h5 class="m-0 text-center" v-if="!user.role.admin"> My Product Bid Request Negotiations  </h5> 
                           <!-- <h5 class="m-0 text-center" v-if="user.role.buyer">My Product Bid Request Negotiations Offer Pending</h5>  -->
                           <!-- <b-button class="m-0 btn float-left" variant="outline-light" @click="myDashboard" size="sm"><i class="fa fa-arrow-left mr-2"></i>Back</b-button> -->
                       </div>
                   </b-card-header>
                   <b-card-body class="px-0">
                       <div v-if="errors.length > 0">
                           <b-alert show variant="danger">
                               <small>{{ errors[0] }}</small>
                           </b-alert>
                       </div>
                       <template v-if="myBidRequestNegotiation.length > 0">
                           <b-list-group>
                               <b-list-group-item  v-for="bidRequest in getUniqueValueFromArray(myBidRequestNegotiation)" :key="bidRequest.id" class="d-block d-md-flex align-items-center add-pointer">
                                   <div class="text-md-left w-100 pl-0 pl-md-4 pr-0 pr-md-4" @click="bidRequestDetails(bidRequest.id)">
                                       <h6 class="mt-2 m-md-0">
                                           {{bidRequest.bidRequest.subtype}} {{bidRequest.bidRequest.type}}
                                       </h6>
                                       <small>Create Date: {{ formatDate(bidRequest.created) }}</small><br />
                                       <small class="d-block d-md-flex"><span>Delivery Window:</span><strong class="d-block d-md-inline flex-fill text-left text-md-left ml-1">{{moment( bidRequest.bidRequest.startDate.toDate()).format('ll')}} to {{moment(bidRequest.bidRequest.endDate.toDate()).format('ll')}}</strong></small>
                                       <!-- <small class="d-block d-md-flex"><span>Number of Acres:</span><strong class="d-block d-md-inline flex-fill text-left text-md-left ml-1">{{ bidRequest.bidRequest.available_acres }}</strong></small> -->
                                   </div>
                                   <div class="text-right mt-2 mt-md-0" v-if="bidRequest.created && bidRequest.bidRequest.userID === user.uid">
                                        <small>{{formatDate(bidRequest.created)}}</small>
                                        <div v-if="bidRequest.is_bidRequest_negotiation_status === undefined" class="d-flex flex-row justify-content-between">
                                            <b-button variant="primary" size="md" @click="updateNegotiationStatus(true, bidRequest)" >Accept</b-button>
                                            <b-button variant="danger" size="md" @click="updateNegotiationStatus(false, bidRequest)" class="ml-2">Decline</b-button>
                                        </div>
                                        <div v-if="bidRequest.is_bidRequest_negotiation_status !== undefined">
                                            <b-badge variant="danger" pill v-if="bidRequest.is_bidRequest_negotiation_status == 0">Rejected</b-badge>
                                            <b-button variant="danger" size="sm" :disabled="removeButton" @click="removeFromNegotiation(bidRequest)">Remove</b-button>
                                        </div>
                                   </div>
                                   <div class="text-right mt-2 mt-md-0" v-if="bidRequest.bidRequest.userID !== user.uid">
                                        <p v-if="bidRequest.is_bidRequest_negotiation_status !== undefined">
                                            <b-badge variant="success" pill v-if="bidRequest.is_bidRequest_negotiation_status == 1">Approved</b-badge>
                                            <b-badge variant="danger" pill v-if="bidRequest.is_bidRequest_negotiation_status == 0">Rejected</b-badge>
                                            <b-button variant="danger" size="sm" :disabled="removeButton" @click="removeFromNegotiation(bidRequest)">Remove</b-button>
                                        </p>
                                        <p v-else>
                                            <b-badge variant="warning" pill>Pending Approval</b-badge>
                                        </p>
                                   </div>
                               </b-list-group-item>
                           </b-list-group>
                       </template>
                       <template v-else>
                            <b>No Product Bid Request Negotiation</b>
                       </template>
                   </b-card-body>
               </b-card>
           </div>
       </div>
       <div>
            <form ref="acceptForm" @submit.prevent="confirmBidRequestNegotiation">
                <b-modal ref="model" size="lg" 
                    title="Confirmation of Negotiation" centered  v-model="confirmationNegotiationPopup" 
                    @show="resetConfirmationModel" 
                    @hide="resetConfirmationModel"
                >
                    <div v-if="confirmNegotiationLoading" class="row mt-4">
                        <div class="col d-none d-md-flex"></div>
                        <div class="col-12 col-md-8 col-xl-6 pt-4">
                            <card class="card-lock text-center mt-4 pb-4" no-footer-line>
                            <h4 class="card-title mb-4"><b-spinner class="mr-2"></b-spinner> Loading...</h4>
                            </card>
                        </div>
                        <div class="col d-none d-md-flex"></div>
                    </div>
                    <div v-else>
                        <p>
                            By entering your password, you acknowledge and consent to enter into a legally binding contract with the Buyer, pursuant to the NGF Global <a href="https://www.ngf-global.com/user-agreement" target="_blank">User Agreement</a>, for the purchase of the product listed.
                        </p>
                        <p v-if="confirmationPopupError" class="alert alert-danger mb-4">{{confirmationPopupError}}</p>
                        <ValidationProvider
                            name="confirmationPassword"
                            rules="required|min:6"
                            v-slot="{ passed, failed, errors }"
                            >
                            <b-form-group
                                :invalid-feedback="errors.length > 0 ? errors[0] : null"
                                :state="failed ? false : passed ? true : null"
                        >
                                <b-form-input
                                type="password"
                                placeholder="Please Enter Password"
                                v-model="confirmationPassword"
                                :class="confirmationPopupError ? 'is-invalid' : ''"
                                :state="failed ? false : passed ? true : null"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <template #modal-footer="{ok,cancel}">
                        <b-button size="xl" :disabled="confirmNegotiationLoading" @click="cancel()">
                            Cancel
                        </b-button>
                        <b-button size="xl" :disabled="confirmNegotiationLoading" variant="success" @click="confirmBidRequestNegotiation">
                            Continue
                        </b-button>
                    </template>
                </b-modal>
            </form>
       </div>
   </div>
</template>

<script>

import { db } from '@/firebase';
import {mapState} from 'vuex';
import { switchMap, map} from 'rxjs/operators';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest,  of } from 'rxjs';
import moment from 'moment';
import {auth} from '@/firebase';
import firebase from 'firebase';




export default {

   computed: {
       ...mapState(['user']),
       sortedBidRequestNegotiationItems: function(){
           const vm = this;
           return [...vm.myBidRequestNegotiation].sort((a, b) => {
               return b.created.toDate() - a.created.toDate();
           });
       }
   },

   data() {
       return {
           loading: false,
           total: 0,
           myBidRequestNegotiation: [],
           errors: [],
           removeButton : false,
           acceptBidNegotiation: null,
           confirmationNegotiationPopup: false,
           confirmationPopupError: null,
            confirmationPassword: null,
            confirmNegotiationLoading: false,
       }
   },
   

   async mounted(){
       const vm = this;
       vm.getMyProductBidRequest();
   },

   methods: {
       async getMyProductBidRequest(){
           const vm = this;
           vm.loading = true;
           vm.myBidRequestNegotiation = [];
           vm.total = 0;
           let ref = await  db.collection('bid_request_negotiation').where('is_restore', '==', null)
           collectionData(ref, 'id').pipe(
               switchMap((bidRequests) => {
                   if(bidRequests.empty) return of([]);
                   return combineLatest(...bidRequests.map(c=>{
                   return docData(db.collection('bid_request').doc(c.bidRequestID)).pipe(
                       map(bidRequest=>{
                       if(JSON.stringify(c) !== '{}'){
                           return {...c, bidRequest: bidRequest}
                       } else {
                           return of({});
                       }                            
                       })
                   )
                   }))
               })
           ).pipe(
               switchMap(bidRequests=>{
                   if(bidRequests.length==0) return of([]);
                   return combineLatest(...bidRequests.map(c=>{
                       return docData(db.collection('commodity_types').doc(c.bidRequest.type)).pipe(
                           map(t=>{
                               return {...c, bidRequest: {...c.bidRequest,type:t.name}}
                           })
                       )
                   }))
               })
           ).pipe(
               switchMap(bidRequests=>{
                   if(bidRequests.length==0) return of([]);
                   return combineLatest(...bidRequests.map(c=>{
                       return docData(db.collection('commodity_subtypes').doc(c.bidRequest.subtype)).pipe(
                       map(t=>{
                           return {...c, bidRequest: {...c.bidRequest,subtype:t.name}}
                       })
                       )
                   }))
               })
           ).subscribe((bidRequests) => {
               if(bidRequests.length != 0){
                   bidRequests.map((item) => {
                       if(item.is_productionContract_negotiation_status != true || item.is_productionContract_negotiation_status == undefined){
                            if(item.bidRequest.userID !== vm.user.uid && item.producerID === vm.user.uid){
                                vm.myBidRequestNegotiation.push(item);
                            } else if (item.bidRequest.userID === vm.user.uid && item.buyerID === vm.user.uid) {
                                vm.myBidRequestNegotiation.push(item);
                            }
                       }
                   });
               }
           }); 
           setTimeout(() => {
               vm.loading = false;                    
           }, 5000);
       },

       getUniqueValueFromArray(products){      
           return [
               ...new Map(
                   products.map((item) => [item['id'], item])
               ).values()
           ]
       },
       formatDate(d){
           if(d){
               d = d.toDate();
               if(moment().diff(d,'minutes')<1) return moment().diff(d,'seconds') + ' secs ago';
               if(moment().diff(d,'hours')<1) return moment().diff(d,'minutes') + ' mins ago';
               if(moment().diff(d,'days')<1) return moment().diff(d,'hours') + ' hrs ago';
               return moment(d).format('ll');
           }else{
               return '';
           }
       },

       async removeFromNegotiation(bidRequestNegotiation){
           const vm = this;
           if(confirm('Are you sure you want Remove this product bid request from negotiation?')){
               vm.removeButton = true;
               let bidRequest = await db.collection('bid_request_negotiation').doc(bidRequestNegotiation.id);
               bidRequest.delete().then(() => {
                   vm.loading = true;
                   vm.removeButton = false;
                   vm.myBidRequestNegotiation = [],
                   vm.getMyProductBidRequest();
               });
           }       
       },

       bidRequestDetails(bidRequestID){
           const vm = this;
           vm.$router.push('/bid/request/negotiation/'+bidRequestID)
       },

       async updateNegotiationStatus(negotiationStatus, bidRequestNegotiation){
            const vm = this;
            vm.acceptProductNegotiation = null;
            if(negotiationStatus){
                vm.acceptBidNegotiation = bidRequestNegotiation;
                vm.confirmationNegotiationPopup = true;
            } else {
                if(confirm('Are you sure want to decline payment request?')){
                    let ref = db.collection('bid_request_negotiation').doc(bidRequestNegotiation.id);
                    ref.update({is_bidRequest_negotiation_status:  0, negotiation_id: bidRequestNegotiation.id}).then(async() => {
                        vm.confirmNegotiationLoading = false;
                        vm.confirmationNegotiationPopup = false;
                    });
                }
            }
       },

       resetConfirmationModel(){
            let vm = this;
            vm.confirmationPopupError = null;
            vm.confirmationPassword = null;
            vm.selectNegotiationList = false;
        },


       async confirmBidRequestNegotiation(){
            const vm = this;
            if(vm.confirmationPassword != null && vm.confirmationPassword.length >= 6){
                let isAuth = await auth.signInWithEmailAndPassword(vm.user.about.email, vm.confirmationPassword).then(async() => {
                    return true;
                }).catch(() => {
                    vm.confirmationPopupError = "This password is invalid."
                    vm.confirmNegotiationLoading = false;
                    setTimeout(() => {
                        vm.confirmationPopupError = null;
                    }, 4000);
                    return;
                });
                if(isAuth){
                    vm.loading = true;
                    vm.confirmNegotiationLoading = true;
                    if(vm.acceptBidNegotiation !== null){
                        let ref = db.collection('bid_request_negotiation').doc(vm.acceptBidNegotiation.id);
                        await ref.update({is_bidRequest_negotiation_status: 1 ,negotiation_id: vm.acceptBidNegotiation.id, is_buyer_verified: true }).then(async() => {
                            await vm.completeBidRequest();
                            vm.confirmNegotiationLoading = false;
                            vm.confirmationNegotiationPopup = false;   
                            vm.myBidRequestNegotiation = [],
                            vm.getMyProductBidRequest();   
                        });
                        return;
                    }
                   
                }
            }
       },

       async completeBidRequest(){
            const vm = this;
            let data = {};
            let ref = db.collection('bid_request_complete').doc();
            let id =  ref.id;
            data.id = id;
            data.bidRequestID = vm.acceptBidNegotiation.bidRequestID;
            data.buyerID = vm.user.uid;
            data.producerID = vm.acceptBidNegotiation.producerID;
            data.fullFillAmount = vm.acceptBidNegotiation.fullFillAmount;
            data.bidRequestNegotiationId = vm.acceptBidNegotiation.id;
            data.status = 'completed';
            data.created = firebase.firestore.FieldValue.serverTimestamp();
            data.updated = firebase.firestore.FieldValue.serverTimestamp();
            if(vm.acceptBidNegotiation.offerPrice) { data.offerPrice = vm.acceptBidNegotiation.offerPrice }
            data.total = (((vm.acceptBidNegotiation.offerPrice) ?  vm.acceptBidNegotiation.offerPrice : vm.acceptBidNegotiation.bidRequest.price) * vm.acceptBidNegotiation.fullFillAmount);
            await db.collection('bid_request_complete').doc(id).set(data).then(async() =>{
                let ref = await db.collection('bid_request_negotiation').doc(vm.acceptBidNegotiation.id);
                ref.delete().then(async() => {
                    await vm.deactivateBidRequest();
                });   
            });
            return;
        },

        async deactivateBidRequest(){
            const vm = this;
            let ref = await db.collection('bid_request')
                              .doc(vm.acceptBidNegotiation.bidRequestID)
                              .get();
            let bidRequest = null;
            if(ref.exists) bidRequest = {...ref.data()};
            if(parseInt(bidRequest.accepted_full_fill_amount) === 0 && bidRequest.accepted_full_fill_amount != undefined) {
                let ref = await db.collection('bid_request').doc(vm.acceptBidNegotiation.bidRequestID)
                ref.update({'active': false});
            }
            
        },
   }
}
</script>